$('#campo-busca .botao').click(function(){
	var form =  $(this).parents('form');
  var input = form.find('.form-control');
  
  if(form.hasClass('active')){
  	
    if(input.val() != ''){
    	form.submit();
		}else{
    	form.removeClass('active');
    }
    
  }else{
  	form.addClass('active');
    
    return false;
	}
});