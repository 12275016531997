$('[data-toggle="buscar"]').click(function(){
	var target = $(this).data('target');

	$(target).addClass('show');
	$('.bg-busca').fadeIn(400);
});

$('.bg-busca').click(function(){
	$('#campoBuscaResponsivo').removeClass('show');

	$(this).fadeOut(400);
});