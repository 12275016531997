var TopoFixo = {
	jaVerify 	: false
};

TopoFixo.verify = function(){
	var barMenu 	= $('.topo');
	var topo 		= $('.topo');
	var th 			= topo.outerHeight();

	if($(window).scrollTop() >= topo.outerHeight()){
		// topo.css('min-height', topo.outerHeight());
		barMenu.addClass('fx');
	}else{
		barMenu.removeClass('fx');
		topo.removeAttr('style');
		
	}

};

TopoFixo.autostart = (function(){

	TopoFixo.verify();

	$(window).scroll(function(){
		if(TopoFixo.jaVerify) return;

		setTimeout(function(){
			TopoFixo.jaVerify = false;
		}, 100);

		TopoFixo.verify();
	});

})();